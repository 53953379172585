<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:calc(100% - 32px);">
                    <a-card title="H5" style="width:100%;min-height:100%;" :bordered="false">
                        <a-form-model layout="horizontal" style="width:800px;"
                                      ref="ruleForm"
                                      :rules="rules"
                                      :model="FormModel"
                                      :label-col="formItemLayout.labelCol"
                                      :wrapper-col="formItemLayout.wrapperCol">
                            <a-form-model-item label="H5标题" style="margin-bottom:10px;"
                                               :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperCol"
                                               prop="Name">
                                <a-input placeholder="请输入标题" v-model="FormModel.Name" onkeyup="this.value=this.value.replace(/\s+/g,'')" :disabled="disabled"></a-input>
                            </a-form-model-item>
                            <a-form-model-item label="H5作者" style="margin-bottom:10px;"
                                               :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperCol"
                                               prop="Author">
                                <a-input placeholder="请输入作者" v-model="FormModel.Author" onkeyup="this.value=this.value.replace(/\s+/g,'')" :disabled="disabled"></a-input>
                            </a-form-model-item>
                            <a-form-model-item label="摘要"
                                               style="margin-bottom: 10px"
                                               :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperCol"
                                               prop="Summary">
                                <a-input placeholder="摘要"
                                         type="input"
                                         v-model="FormModel.Summary"
                                         onkeyup="this.value=this.value.replace(/\s+/g,'')"
                                         :maxLength="20"
                                         :disabled="disabled"
                                         style="outline: none"></a-input>
                            </a-form-model-item>
                            <a-form-model-item label="H5LOGO" style="margin-bottom:20px;"
                                               :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperCol">
                                <a-upload name="image"
                                          :data="ParamData"
                                          list-type="picture-card"
                                          class="avatar-uploader"
                                          action="/FileServer/Upload"
                                          :fileList="FormModel.fileList"
                                          @change="imgChanges"
                                          :disabled="disabled">
                                    <div v-if="FormModel.fileList.length < 1">
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">上传图片</div>
                                    </div>
                                </a-upload>
                            </a-form-model-item>
                            <a-form-model-item label="背景音乐" style="margin-bottom:20px;"
                                               :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperCol">
                                <a-upload name="music"
                                          :data="ParamDataMusic"
                                          list-type="picture-card"
                                          class="avatar-uploader"
                                          action="/FileServer/Upload"
                                          :fileList="FormModel.MusicfileList"
                                          @change="MusicChanges"
                                          :disabled="disabled">
                                    <div v-if="FormModel.MusicfileList.length < 1">
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">上传音乐</div>
                                    </div>
                                </a-upload>
                            </a-form-model-item>
                            <a-form-model-item label="选择模版" style="margin-bottom:10px;"
                                               :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperCol"
                                               prop="Template">
                                <a-input placeholder="点击选择模版" disabled v-model="FormModel.TemplateName" />
                                <a-button type="primary" value="点击选择" size="small" @click="showTemplate" :disabled="Template.disabled">点击选择</a-button>
                            </a-form-model-item>
                            <a-form-model-item label="编辑页" style="margin-bottom:10px;"
                                               :label-col="formItemLayout.labelCol"
                                               :wrapper-col="formItemLayout.wrapperCol"
                                               prop="ConfigH5">
                                <a-button class="editable-add-btn" @click="handleAdd" :disabled="disabled">
                                    新增
                                </a-button>
                                <a-table bordered :data-source="dataSource" :columns="columns">
                                    <template slot="PageText" slot-scope="text, record">
                                        <EditableCell :text="text" :disabled="disabled" @change="onCellChange(record.key, 'PageText', $event)" />
                                    </template>
                                    <template slot="PageContent" slot-scope="text, record">
                                        <EditableRichEditor :text="text" :disabled="disabled" @change="onCellChange(record.key, 'PageContent', $event)" />
                                    </template>
                                    <template slot="PageImage" slot-scope="text, record">
                                        <EditableImg :text="text" :disabled="disabled" @change="onCellChange(record.key, 'PageImage', $event)" />
                                    </template>
                                    <template slot="operation" slot-scope="text, record">
                                        <a-popconfirm v-if="dataSource.length"
                                                      title="确认删除吗?"
                                                      @confirm="() => onDelete(record.key)">
                                            <a href="javascript:;" :disabled="disabled">删除</a>
                                        </a-popconfirm>
                                    </template>
                                </a-table>
                            </a-form-model-item>
                        </a-form-model>
                    </a-card>
                    <a-card>
                        <a-button type="primary" @click="submitForm('ruleForm')" :disabled="disabled">
                            保存
                        </a-button>
                    </a-card>
                </div>
            </a-layout-content>
            <a-modal v-model="Template.IsDisplay"
                     title="模版选择"
                     centered
                     okText="确定"
                     cancelText="取消"
                     width="900px"
                     height="700px"
                     @ok="onTemplateOk">
                <div style="width: 100%; height:auto;">
                    <ul class="form-search">
                        <li style="margin:0">
                            <label>名称</label>
                            <a-input placeholder="输入名称" v-model="Template.Name"></a-input>
                        </li>
                    </ul>
                    <a-button type="primary" value="查询" @click="getTemplateList" size="small" style="margin-left:12px;height:28px;padding-left:12px;padding-right:12px;">查询</a-button>
                    <div id="boxdiv" style="padding-top:16px;">
                        <a-radio-group v-model="Template.ID" @change="onTemplateChange">
                            <table id="example2" class="table  table-hover">
                                <thead>
                                    <tr>
                                        <th width="40"></th>
                                        <th width="100">商品图片</th>
                                        <th>名称</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in Template.tableData" :key="item.ID">
                                        <td>
                                            <a-radio :value="item.ID"></a-radio>
                                        </td>
                                        <td align="center">
                                            <img :src="item.PreViewImg" height="80" width="80" />
                                        </td>
                                        <td title="Name">{{item.Name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </a-radio-group>
                    </div>
                </div>
            </a-modal>
        </a-layout>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import EditableCell from "@/components/EditableCell.vue";
    import EditableImg from "@/components/EditableImg.vue";
    import EditableRichEditor from "@/components/EditableRichEditor.vue";
    export default {
        name: "",
        data() {
            return {
                disabled: false,
                ActivityID: 0,
                MaterialID: 0,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                FormModel: {
                    Name: "",
                    Author: "",
                    fileList: [],
                    MusicfileList: [],
                    TemplateName: "",
                    Summary: "",
                    MaterialAward: {
                        BeginTime: "",
                        EndTime: "",
                        GPSLimit: false,
                        ReadSecond: 1,
                        ReadMoney: 0,
                        PersonMaxMoney: 0,
                        BudgetMoney: 0,
                        Lat: 36.062233,
                        Lng: 114.383176,
                        Radius: 10,
                        CenterPointAddress:
                            "以【 河南省安阳市文峰区弦歌大道 】为中心方圆【 10 】公里",
                        MaterialID: "",
                        Name: "",
                        CustomID: "",
                    },
                },
                rules: {
                    Name: [{ message: "请输入H5标题", required: true, trigger: "blur" }],
                    Author: [{ message: "请输入H5作者", required: true, trigger: "blur" }],
                    Summary: [{ message: "请输入摘要", required: true, trigger: "blur" }],
                },
                Topic: { ID: 7 },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                ParamDataMusic: {
                    ProcessorName: "Music",
                    JsonDataParams: ""
                },
                Template: {
                    ID: 0,
                    IsDisplay: false,
                    Name: '',
                    tableData: [],
                    disabled: false
                },
                dataSource: [],
                count: 0,
                columns: [
                    {
                        title: '序号',
                        customRender: (text, record, index) => `${index + 1}`,
                    },
                    {
                        title: '名称',
                        dataIndex: 'PageText',
                        width: 150,
                        scopedSlots: { customRender: 'PageText' },
                    },
                    {
                        title: '内容',
                        dataIndex: 'PageContent',
                        width: 150,
                        scopedSlots: { customRender: 'PageContent' },
                    },
                    {
                        title: '图片',
                        dataIndex: 'PageImage',
                        scopedSlots: { customRender: 'PageImage' },
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        scopedSlots: { customRender: 'operation' },
                    },
                ],
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var self = this;
                        if (self.checkTemplate() && self.checkConfigH5() && self.checkImage()) {
                            var op = {
                                url: "/MarketingModule/Material/EditMaterial",
                                data: {
                                    ID: self.MaterialID,
                                    Name: self.FormModel.Name,
                                    Author: self.FormModel.Author,
                                    Summary: self.FormModel.Summary,
                                    MaterialType: 2,
                                    Topic: self.Topic,
                                    TemplateID: self.Template.ID,
                                    Music: self.FormModel.MusicfileList.length > 0 ? self.FormModel.MusicfileList[0].response : "",
                                    Logo: self.FormModel.fileList.length > 0 ? self.FormModel.fileList[0].response : "",
                                    MaterialAward: self.FormModel.MaterialAward,
                                },
                                OnSuccess: function (response) {
                                    console.log(formName + response);
                                    self.AddMaterialConfigH5(response.data);
                                }
                            };
                            http.Post(op);
                        } else {
                            return false;
                        }
                    }
                });
            },
            AddMaterialConfigH5: function (ID) {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/AddMaterialConfigH5",
                    data: {
                        info: JSON.stringify(self.dataSource),
                        MaterialID: ID
                    },
                    OnSuccess: function (response) {
                        console.log(response);
                        self.AddM2ActivityCarrier(ID);
                    }
                };
                http.Post(op);
            },
            AddM2ActivityCarrier: function (ID) {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/AddM2ActivityCarrier",
                    data: {
                        ActivityID: self.ActivityID,
                        MaterialID: ID,
                        SceneType: 0
                    },
                    OnSuccess: function (response) {
                        console.log(response);
                        self.$message.success('保存成功', 1.5, function () { });
                        self.$router.push({
                            name: "M2Activity_List"
                        });
                    }
                };
                http.Post(op);
            },
            loadData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialInfo",
                    data: {
                        id: self.MaterialID
                    },
                    OnSuccess: function (data) {
                        self.FormModel.Author = data.data.Author;
                        if (self.MaterialID != 0) {
                            self.FormModel.Name = data.data.Name;
                            self.FormModel.Summary = data.data.Summary;
                            self.FormModel.TemplateName = data.data.MaterialTemplate.Name;
                            self.dataSource = data.data.MaterialConfigH5;
                            var Logo = data.data.Logo;
                            if (Logo != "" && Logo != null) {
                                self.FormModel.fileList = [{
                                    name: Logo,
                                    response: Logo,
                                    status: "done",
                                    uid: "0",
                                    url: Logo
                                }];
                            }
                            else {
                                self.FormModel.fileList = [];
                            }
                            var Music = data.data.Music;
                            if (Music != "" && Music != null) {
                                self.FormModel.MusicfileList = [{
                                    name: Music,
                                    response: Music,
                                    status: "done",
                                    uid: "0",
                                    url: Music
                                }];
                            }
                            else {
                                self.FormModel.MusicfileList = [];
                            }
                        }
                    }
                };
                http.Post(op);
            },
            imgChanges: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;
                }
                self.FormModel.fileList = info.fileList;
            },
            MusicChanges: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;
                }
                self.FormModel.MusicfileList = info.fileList;
            },
            showTemplate: function () {
                var self = this;
                self.Template.IsDisplay = true;
            },
            onTemplateOk: function () {
                var self = this;
                self.Template.IsDisplay = false;
                var result = self.Template.tableData.filter(function (item) {
                    return item.ID == self.Template.ID;
                });
                self.FormModel.TemplateName = result[0].Name;
            },
            getTemplateList: function () {
                var self = this
                var op = {
                    url: "/MarketingModule/Material/GetMaterialTemplateList",
                    data: {
                        MaterialType: 2,
                        Name: self.Template.Name
                    },
                    OnSuccess: function (data) {
                        if (data.data.Data.length > 0) {
                            self.Template.tableData = data.data.Data;
                            self.Template.ID = data.data.Data[0].ID;
                        } else {
                            self.Template.tableData = [];
                            self.Template.ID = 0;
                        }
                    }
                };
                http.Post(op);
            },
            onTemplateChange(e) {
                var self = this;
                self.Template.ID = e.target.value;
            },
            checkTemplate: function () {
                var rt = true;
                var self = this;
                if (self.FormModel.TemplateName == '') {
                    rt = false;
                    self.$message.error("请选择模版！");
                }
                return rt;
            },
            handleAdd() {
                const { count, dataSource } = this;
                const newData = {
                    key: count,
                    PageText: `名称`,
                    PageContent: `内容`,
                    PageImage: ``,
                };
                this.dataSource = [...dataSource, newData];
                this.count = count + 1;
            },
            onCellChange(key, dataIndex, value) {
                const dataSource = [...this.dataSource];
                const target = dataSource.find(item => item.key === key);
                if (target) {
                    target[dataIndex] = value;
                    this.dataSource = dataSource;
                }
            },
            onDelete(key) {
                const dataSource = [...this.dataSource];
                this.dataSource = dataSource.filter(item => item.key !== key);
            },
            checkConfigH5: function () {
                var rt = true;
                var self = this;
                if (self.dataSource.length == 0) {
                    rt = false;
                    self.$message.error("请编辑页！");
                }
                return rt;
            },
            checkImage: function () {
                var rt = true;
                var self = this;
                if (self.FormModel.fileList != null) {
                    if (self.FormModel.fileList.length == 0) {
                        rt = false;
                        self.$message.error("请上传LOGO！");
                    }
                }
                return rt;
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            EditableCell,
            EditableImg,
            EditableRichEditor,
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            this.ActivityID = this.$route.query.ActivityID;
            this.MaterialID = this.$route.query.MaterialID;
            if (this.MaterialID != 0) {
                this.Template.disabled = true;
            }
            if (this.$route.query.disabled == 1) {
                this.disabled = true;
            }
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.loadData();
            this.getTemplateList();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
<style type="text/css">
    .content {
        min-height: 250px;
        padding: 15px;
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .content {
        margin: 15px 20px 15px 15px;
        padding-top: 0;
    }

    .form-search {
        display: block;
        list-style-type: disc;
        padding-left: 0;
    }

    ul, ol {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .box {
        border-top: none;
        box-shadow: none;
    }

    .right-btn {
        margin-top: 18px;
    }

    .line-btn {
        border: 1px solid #32a9ff;
        color: #32a9ff;
        padding: 7px 15px;
        background: #fff;
        transition: all .15s ease-in-out;
    }

    .gray-line-btn {
        border: 1px solid #e5e5e5;
        color: #666;
        padding: 7px 15px;
        background: #fff;
        transition: all .15s ease-in-out;
        margin-left: 20px;
    }

    .gray-line-btn {
        cursor: pointer
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        margin-top: -3px;
    }

        .solid-btn:hover {
            background: #1b99f5;
            cursor: pointer;
            color: #fff
        }

    .num {
        font-size: 26px;
        color: #32a9ff;
        padding: 5px;
        font-weight: 500;
    }

    .inners {
        padding: 10px;
        border: 1px solid #f3f4f5
    }

    .search {
        overflow: hidden;
        background: #fbfbfb;
        margin: 10px auto;
        padding: 10px;
    }

    .line-btn:hover {
        background: #32a9ff;
        color: #fff;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        cursor: pointer;
    }

    .form-search {
        display: block;
        list-style-type: disc;
        padding-left: 0;
    }

    .mtb5 {
        margin: 5px 0
    }

    .mtb10 {
        margin: 10px 0;
    }

    .mtb20 {
        margin: 20px 0;
    }

    .tr-middle td {
        vertical-align: middle;
    }

    body {
        background: #f3f5f5;
        font-family: Arial,Helvetica,sans-serif,"Microsoft YaHei","ËÎÌå";
        font-size: 15px;
    }

    .sys-name {
        color: #00a2e9;
        margin: 0;
        padding: 0;
        font-size: 25px;
        font-weight: 500;
        height: 68px;
        line-height: 68px;
    }

    .right-opt {
        height: 68px;
        line-height: 68px;
        cursor: pointer;
    }

    .top-top {
        margin-bottom: 10px;
        background: #fff;
        border-bottom: solid 1px #E0E5E5;
    }

    .top-center {
        max-width: 1160px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .content-wrapper {
        background: #fff;
    }

    .content {
        margin: 15px 20px 15px 15px;
        padding-top: 0
    }

    .box-header {
        border-bottom: 1px solid #f3f5f5;
        padding: 0 10px;
    }

        .box-header > .box-title {
            height: 62px;
            line-height: 62px;
        }

    .top-center a[href] {
        color: #32a9ff;
    }

    a {
        color: #32a9ff;
    }

    .pager li > a {
        border: none;
    }

    .main-right-search {
        padding: 20px;
        border-radius: 4px;
        -moz-border-radius: 4px;
        background-color: #fbfbfb;
        padding-top: 0;
    }

    .form-search li {
        float: left;
        overflow: hidden;
        margin-right: 17px;
        width: 350px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        border: solid 1px #E0E5E5;
        border-radius: 4px;
        -moz-border-radius: 4px;
        list-style: none;
        margin-top: 8px;
        margin-bottom: 0;
        padding: 0;
    }

        .form-search li label {
            float: left;
            width: 110px;
            color: #666;
            text-align: center;
            border-right: solid 1px #E0E5E5;
        }

        .form-search li input {
            float: left;
            border: none;
            background: none;
            outline: none;
            text-indent: 6px;
            -webkit-appearance: none;
            background-color: #ffffff;
            width: 235px;
            height: 26px;
            color: #333333;
            line-height: 28px;
        }

    .form-search-margin {
        margin-top: 12px;
    }

    .form-search li .inline-text {
        padding-left: 5px;
    }

    .pager {
        padding-left: 0;
        margin: 20px 0;
        text-align: center;
        list-style: none;
    }

        .pager li {
            display: inline;
        }

        .pager ul li {
            list-style: none outside none;
        }

        .pager li > a {
            border: none;
        }

        .pager li > a, .pager li > span {
            display: inline-block;
            padding: 5px 14px;
            background-color: #fff;
            border-radius: 15px;
        }

    .table {
        width: 100%;
        margin-bottom: 20px;
    }
</style>
<style>
    .editable-cell {
        position: relative;
    }

    .editable-cell-input-wrapper,
    .editable-cell-text-wrapper {
        padding-right: 24px;
    }

    .editable-cell-text-wrapper {
        padding: 5px 24px 5px 5px;
    }

    .editable-cell-icon,
    .editable-cell-icon-check {
        position: absolute;
        right: 0;
        width: 20px;
        cursor: pointer;
    }

    .editable-cell-icon {
        line-height: 18px;
        display: none;
    }

    .editable-cell-icon-check {
        line-height: 28px;
    }

    .editable-cell:hover .editable-cell-icon {
        display: inline-block;
    }

    .editable-cell-icon:hover,
    .editable-cell-icon-check:hover {
        color: #108ee9;
    }

    .editable-add-btn {
        margin-bottom: 8px;
    }
</style>