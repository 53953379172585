<template>
    <div class="editable-richeditor">
        <Rich v-bind:value.sync="value" rows="800" :disabled="ondisabled" @update:value="handleChange"></Rich>
    </div>
</template>
<script>
    import Rich from "@/components/RichEditor.vue";
    export default {
        name: "EditableRichEditor",
        props: {
            text: String,
            disabled: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                value: this.text,
                ondisabled: this.disabled,
            };
        },
        methods: {
            handleChange(e) {
                this.value = e;
                this.$emit('change', e);
            }
        },
        components: {
            Rich
        }
    };
</script>