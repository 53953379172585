<template>
    <div class="editable-img">
        <a-upload name="image"
                  :data="ParamData"
                  list-type="picture-card"
                  class="avatar-uploader"
                  action="/FileServer/Upload"
                  :fileList="fileList"
                  @change="imgChanges"
                  :disabled="disabled">
            <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
            </div>
        </a-upload>
    </div>
</template>
<script>
    export default {
        name: "EditableImg",
        data() {
            return {
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                fileList: [],
                value: this.text,
                ondisabled: this.disabled,
            };
        },
        props: {//组件属性
            text: String,
            disabled: {
                type: Boolean,
                default: false,
            }
        },
        methods: {//方法
            imgChanges: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;
                }
                self.fileList = info.fileList;
                var url = '';
                if (self.fileList.length > 0) {
                    url = self.fileList[0].response;
                }
                this.$emit('change', url);
            },
            loadData: function () {
                var self = this;
                if (self.value != "") {
                    self.fileList = [{
                        name: self.value,
                        response: self.value,
                        status: "done",
                        uid: "0",
                        url: self.value
                    }];
                }
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.loadData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>